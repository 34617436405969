import React, { useState, useEffect } from "react";

import SelectTable from "../../../Components/Chavelusa/SelectTable";
import Window from "../../../Components/Chavelusa/Window";
import ClientForm from "../../../Components/CRM/Client/Form";
import TemplatesForm from '../../../Components/Backoffice/Templates/Form';

import {
    Grid,
    Snackbar,
    Alert,
    Button
} from '@material-ui/core';

import {
    getCols,
    getRow,
    buildFilters,
    buildTutorial,
    generate_exports,
    multiple_selection_ops
} from './utils';

import {
    canReadAdministrativeData
} from '../../../../Services/permissions';

import {
    buildParams,
    requestGET,
    handleRequestError
} from '../../../utils';

let aborter = null;

const ClientList = ({duplicates}) => {
    // Loading
    const [loading, setLoading] = useState(true);
    // Content loading and management
    const url = process.env.REACT_APP_API_URL + '/crm/client_agents/';
    const urlDuplicates = process.env.REACT_APP_API_URL + '/crm/client_duplicates/';
    const [params, setParams] = useState({});
    const [data, setData] = useState(null);
    const [duplicateClients, setDuplicateClients] = useState(0);
    // Filters
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        // Load filter parameters from API
        if (!duplicates) {
            requestGET(url + 'filters/')
                .then(response => {
                    setFilters(buildFilters(response));
                }).catch(error => 
                    handleRequestError(
                        error, 
                        [], 
                        "Error getting filters",
                    )
                ).finally(() => {
                    setLoading(false);
                });
        }

        // Load client duplicates
        if (!duplicates && canReadAdministrativeData) {
            requestGET(urlDuplicates)
                .then(response => {
                    setDuplicateClients(response['count']);
                }).catch(error => 
                    handleRequestError(
                        error, 
                        [], 
                        "Error getting clients duplicate",
                    )
                );
        }
    }, []);

    useEffect(() => {
        // Load clients from API
        loadClients();
    }, [params]);

    const loadClients = () => {
        setLoading(true);

        // cancel pending request if any
        if(aborter) aborter.abort();
        // make our request cancellable
        aborter = new AbortController();
        const signal = aborter.signal;

        requestGET((duplicates ? urlDuplicates : url) + buildParams(params), signal)
            .then(response => {
                aborter = null;
                setData(response);
                setLoading(false);
            }).catch(error => 
                handleRequestError(
                    error, 
                    [], 
                    "Error getting clients list"
                )
            ).finally(() => {
                aborter = null;
            });
    }

    const addParam = (newVals) => {
        setParams({
            ...params,
            ...newVals
        })
    }

    /**
     * A method to update current state 
     * @param {String[]} paramsRemove An array with the ids of the fields to delete
     * @param {String} id The id of the field to update 
     * @param {*} val The value to update the last with     
     */
    const updateParams = (paramsRemove, id = null, val = null) => {
        let copy = null;
        if (id) {
            copy = { ...params, [id]: val }
        } else {
            copy = { ...params }
        }
        paramsRemove.forEach((key) => {
            delete copy[key];
        })
        setParams(copy);
    }

    // Window 
    const [window, setWindow] = useState(undefined);
    const [windowChanged, setWindowChanged] = useState(false);
    useEffect(() => {
        // When window changes, reset changed property
        if (window===null)
            setWindowChanged(false);
    }, [window]);

    // Notification 
    const [notify, setNotify] = useState(undefined);

    // Multiple options components
    multiple_selection_ops[0]['onClick'] = (ids) => {
        let recipients = {};
        ids.forEach(id => recipients[id] = 'crm.ClientAgent');
        setWindow(
            <TemplatesForm
                recipients={recipients}
                success={(entities) => {
                    setWindow(null);
                    setNotify("A mensagem foi enviada com sucesso!"); 
                }}
            />
        )
    }
    return (
        <Grid className='client_agents_list' container>
            {
                !duplicates && canReadAdministrativeData && duplicateClients>0 &&
                <Alert 
                    severity="info"
                    sx={{
                        width: '100%',
                        mb: 3
                    }}
                    action={
                        <Button 
                            color="inherit" 
                            href="/clientes/duplicados"
                            sx={{ml: 'auto'}}
                        >
                            Desbloquear clientes    
                        </Button>
                    }
                >
                    Existem {duplicateClients} clientes duplicados que aguardam o desbloqueio!    
                </Alert>
            }
            <SelectTable
                entityNameSingular={duplicates ? 'cliente duplicado' : 'cliente'}
                data={data}
                loading={loading}
                cols={getCols(duplicates)}
                getRow={(obj) => getRow(obj, duplicates)}
                defaultOrder='desc'
                defaultOrderBy='created'
                filters={duplicates ? undefined : filters}
                params={params}
                addParam={addParam}
                updateParams={updateParams}
                docsExport={duplicates ? null : generate_exports(url)}
                multipleSelectionOps={duplicates ? null : multiple_selection_ops}
                allowSelect={duplicates ? false : !window}
                addNew={
                    duplicates 
                    ? null 
                    : () => setWindow(
                        <ClientForm
                            client={null}
                            clientAgentId={null}
                            agent={null}
                            success={(entity) => { 
                                if (entity.id) {
                                    setNotify("O cliente foi criado com sucesso!"); 
                                    loadClients(); 
                                } else {
                                    setWindow(null);
                                }
                            }}
                            formChange={() => setWindowChanged(true)}
                        />
                    )
                }
                tutorial={duplicates ? null : buildTutorial}
                allowSearch={true}
            />

            {
                window &&
                <Window close={() => setWindow(null)} hasChanged={windowChanged}>{window}</Window>
            }
            {
                notify &&
                <Snackbar
                    open={notify != undefined && notify != false}
                    autoHideDuration={6000}
                    onClose={() => setNotify(undefined)}
                >
                    <Alert onClose={() => setNotify(undefined)} severity="success" sx={{ width: '100%' }}>
                        {notify}
                    </Alert>
                </Snackbar>
            }
        </Grid>
    );
}

export default ClientList;