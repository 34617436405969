import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Alert,
    Button,
    TextField
} from '@mui/material';

import {
    requestWithBody,
    handleRequestError
} from '../../../../utils';

const PropertyReviewChangeStatusDialog = ({ property, onSuccess, setNotify }) => {

    const [transition, setTransition] = useState(undefined);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const changeStatus = () => {
        if (!transition || !comment)
            setLoading(true);

        requestWithBody("POST", process.env.REACT_APP_API_URL + '/realstate/reviews/', {
            property: property.id,
            message: comment,
            status_change: transition
        }).then(response => {
            console.log("Message POST success", response);
            setNotify({ text: "Estado alterado com sucesso." });
            onSuccess();
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error POSTing message",
            ).then(e => setNotify({ text: "Ocorreu um erro, tente novamente", error: true }))
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Grid>
            <Typography variant="body2">Estado atual</Typography>
            <Typography>{property.status_display}</Typography>
            <Typography variant="body2" mt={3}>Alterar para...</Typography>
            {
                property.status_transitions.length === 0 &&
                <Typography>
                    Não há transições disponíveis.
                </Typography>
            }
            {
                property.status_transitions.length > 0 &&
                <>
                    <RadioGroup
                        onChange={(event) => setTransition(event.target.value)}
                    >
                        {
                            property.status_transitions.map(t => <Grid>
                                <FormControlLabel
                                    value={t.status}
                                    control={<Radio />}
                                    label={t.status_display}
                                    disabled={!t.valid || !t.has_permission || loading}
                                />
                                {
                                    (t.errors.length > 0 || !t.has_permission) &&
                                    <Alert severity="warning">
                                        <Typography>Alteração impossibilitada por:</Typography>
                                        <ul>
                                            {!t.has_permission && <li>Não tem permissão</li>}
                                            {t.errors.map(e => <li>{e}</li>)}
                                        </ul>
                                    </Alert>
                                }
                            </Grid>)
                        }
                    </RadioGroup>
                    <TextField
                        sx={{
                            width: '100%',
                            my: 2
                        }}
                        label={'Comentário'}
                        multiline
                        minRows={1}
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                        disabled={loading}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ mt: 2, width: '100%' }}
                        onClick={changeStatus}
                        disabled={!transition || !comment || loading}
                    >
                        Alterar estado
                    </Button>
                </>
            }
        </Grid>
    );
}

export default PropertyReviewChangeStatusDialog;