/**
 * Get cookie by name
 */
 export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// PERMISSIONS
export const ROLE = getCookie('role');
export const USER_ID = getCookie('user_id') && parseInt(getCookie('user_id'));

export const is_agent = (ROLE === 'AGENT');
export const is_admin = (ROLE === 'ADMINISTRATOR');
export const is_coor = (ROLE === 'COORDINATOR');

export const canReadGenericData = (is_admin || is_coor);
export const canReadSensitiveData = (is_admin || is_coor);
export const canReadAdministrativeData = is_admin;

export const canWriteGenericData = (is_admin || is_coor);
export const canWriteSensitiveData = is_admin;
export const canWriteAdministrativeData = is_admin;
