import {
    Button,
    Tooltip,
    Typography,
    Badge,
    Grid
} from '@material-ui/core';

import {
    Event as EventIcon,
    Home as HomeIcon,
    Star as StarIcon,
    Euro as EuroIcon,
    KingBed as KingBedIcon,
    AttachFile as AttachFileIcon,
    LocationOn as LocationOnIcon,
    FilterList as FilterListIcon
} from '@mui/icons-material';

import {
    getDictsLabelId,
    buildFilterSelect,
    buildFilterSlider,
    buildFilterSwitch
} from '../../../Components/Chavelusa/FormGroup/utils';


/**
 * This variable defines the cols of the clients table
 */
const cols_prepare = [
    {
        id: 'id',
        label: 'Referência'
    },
    {
        id: 'title',
        label: 'Título'
    },
    {
        id: 'match',
        label: 'Correspondência c/ qualificação',
        sort: true
    }
];
export const cols = cols_prepare;

/**
 * This method returns an array of react components, one for each column of the table
 * @param {Object} obj A client object fom the list retrieved from the /client_agents API endpoint
 * @returns {Component[]} One component per column of the table for the client row
 */
const getColorForMatch = (points) => {
    if (points >= 0.8)
        return 'primary'
    return 'secondary'
}
const tooltipContentBreakLines = (text) => {
    return <div style={{ whiteSpace: 'pre-line' }}>{text}</div>
}
export const getRow = (obj) => {
    let row = [];
    // Referencia
    row.push(
        <>
            <HomeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
            {obj.id}
        </>
    );
    // Titulo
    row.push(
        <>
            {obj.title}
        </>
    );
    // Pontuaçao
    const sx={mr: 3, mb:2}
    row.push(
        <>
            <Grid sx={{width: '100%'}}>
                <Tooltip title="Pontuação global" sx={sx}>
                    <Grid container>
                        <StarIcon color={getColorForMatch(obj.classificationMatch.globalMatch)} />
                        <Typography>{parseInt(obj.classificationMatch.globalMatch*100)}%</Typography>
                    </Grid>
                </Tooltip>
            </Grid>
            <Tooltip 
                title={tooltipContentBreakLines(`Preço\n${obj.price}€`)}
                sx={sx}
            >   
                <Badge badgeContent={parseInt(obj.classificationMatch.priceMatch*100).toString() + '%'} color={getColorForMatch(obj.classificationMatch.priceMatch)}>
                    <EuroIcon color={getColorForMatch(obj.classificationMatch.priceMatch)} />
                </Badge>
            </Tooltip>
            <Tooltip 
                title={tooltipContentBreakLines(`Quartos\n${obj.bedrooms}`)}
                sx={sx}
            >
                <Badge badgeContent={parseInt(obj.classificationMatch.bedroomMatch*100).toString() + '%'} color={getColorForMatch(obj.classificationMatch.bedroomMatch)}>
                    <KingBedIcon color={getColorForMatch(obj.classificationMatch.bedroomMatch)} />
                </Badge>
            </Tooltip>
            <Tooltip 
                title={tooltipContentBreakLines(`Tipo de propriedade\n${obj.type.name}`)}
                sx={sx}
            >
                <Badge badgeContent={parseInt(obj.classificationMatch.propertyTypeMatch*100).toString() + '%'} color={getColorForMatch(obj.classificationMatch.propertyTypeMatch)}>
                    <HomeIcon color={getColorForMatch(obj.classificationMatch.propertyTypeMatch)} />
                </Badge>
            </Tooltip>
            <Tooltip 
                title={tooltipContentBreakLines(`Estado da propriedade\n${obj.state}`)}
                sx={sx}
            >
                <Badge badgeContent={parseInt(obj.classificationMatch.clientTypeMatch*100).toString() + '%'} color={getColorForMatch(obj.classificationMatch.clientTypeMatch)}>
                    <AttachFileIcon color={getColorForMatch(obj.classificationMatch.clientTypeMatch)} />
                </Badge>
            </Tooltip>
            <Tooltip 
                title={tooltipContentBreakLines(`Localização\n${obj.parish.name}, ${obj.parish.county.name}, ${obj.parish.county.district.name}`)}
                sx={sx}
            >
                <Badge badgeContent={parseInt(obj.classificationMatch.locationMatch*100).toString() + '%'} color={getColorForMatch(obj.classificationMatch.locationMatch)}>
                    <LocationOnIcon color={getColorForMatch(obj.classificationMatch.locationMatch)} />
                </Badge>
            </Tooltip>
            <Tooltip title="Características procuradas" sx={sx}>
                <Badge badgeContent={parseInt(obj.classificationMatch.featuresMatch*100).toString() + '%'} color={getColorForMatch(obj.classificationMatch.featuresMatch)}>
                    <FilterListIcon color={getColorForMatch(obj.classificationMatch.featuresMatch)} />
                </Badge>
            </Tooltip>
        </>
    )
    return row;
}

/**
 * Builds the clients table filters, given data retrieved from the client_agents/filters API endpoint
 * @param {Object} data The object returned from the API
 * @returns {Object[]} filters An aray of objects that define the filters fields
 */
export const buildFilters = (data) => {
    let filters = [];

    if ('counties' in data) {
        filters.push(
            buildFilterSelect(
                'Concelho',
                'select',
                'location',
                data.counties.map(c=>{return {label: c.name, id: c.id}})
            )
        )
    }

    if ('status' in data) {
        filters.push(
            buildFilterSelect(
                'Estado',
                'multipleselectchips',
                'status__in',
                data.status.filter(s => s.id!=='Q' /* Remove archived */).map(s => { return { label: s.label, id: s.id } })
            )
        )
    }

    if ('filters' in data) {
        filters.push(
            buildFilterSelect(
                'Finalidade',
                'select',
                'state',
                data.filters.states.map(s=>{return {label: s.name, id: s.id}})
            )
        )
    }

    if ('types' in data) {
        filters.push(
            buildFilterSelect(
                'Tipo de propriedade',
                'select',
                'type',
                data.types.map(t=>{return {label: t.name, id: t.id}})
            )
        )
    }

    if ('features' in data) {
        filters.push(
            buildFilterSelect(
                'Características',
                'multipleselect',
                'features',
                data.features.map(f=>{return {label: f.name, id: f.id}})
            )
        )
    }

    if ('filters' in data) {
        filters.push(
            buildFilterSlider(
                'Área (min)',
                'areaMin',
                data.filters.areamin,
                data.filters.areamax
            )
        )
        filters.push(
            buildFilterSlider(
                'Quartos (min)',
                'bedroomsMin',
                data.filters.bedroomsmin,
                data.filters.bedroomsmax
            )
        )
        filters.push(
            buildFilterSlider(
                'Casas de banho (min)',
                'bathroomsMin',
                data.filters.bathroomsmin,
                data.filters.bathroomsmax
            )
        )
    }

    console.log("filters", filters);

    return filters;
}

/**
 * This methos builds the tutorial object
 */
const buildTutorial_partial = []
export const buildTutorial = buildTutorial_partial;

/**
 * Builds the export objects for SelectTable
 * @param {String} baseUrl 
 */
export const generate_exports = (baseUrl) => {
    return []
}

// FLAGS
export const enableSelect = true;