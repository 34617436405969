/**
 * Returns the default value of a given field.
 * 
 * @param {Object} field See buildFilter<> methods in this file for structure
 * @returns 
 */
export const resetFieldValue = (field) => {
    switch (field.type) {
        case 'select':
            return '';
        case 'multipleselect':
            return [];
        case 'multipleselectchips':
            return [];
        case 'switch':
            return field.default;
        case 'slider':
            return field.min;
    }
    return '';
}

/**
 * The methods below buils the expected stucture to declare a FormGroup field
 * 
 * The dependency objects identifies the id of the field and the values that
 * it must have for it to be rendered.
 */

/**
 * Select and MultipleSelect fields
 * 
 * @param {String} label The label visible to the user.
 * @param {String} type One of [select, multipleselect, multipleselectchips] 
 * @param {String} id The intenal identifier of the field, used to update value 
 * @param {Array} values List of objects 
 *  {label: String, id: String} OR {group: true, label: String}
 * @param {Object} dependency [{id: String, values: String[]}]
 * @param (Optional) {Boolean} mandatory 
 * @param (Optional) {Method} validator Method that validates field value. See methods validator<> below in this file
 * @param (Optional) {String} errorMessage A custom message to show if validator returns false. If null, a default error message will be shown.
 * @param (optional) {Boolean} autoComplete If true, instead of default select, a select with text search is rendered
 */
export const buildFilterSelect = (label, type, id, values, dependency=null, mandatory=false, validator=null, errorMessage=undefined, autoComplete=false) => {
    return {
        label: label,
        type: type,
        id: id,
        values: values,
        dependency: dependency,
        mandatory: mandatory,
        validator: validator,
        errorMessage: errorMessage,
        // disabled: disabled,
        autoComplete: autoComplete
    }
}

/**
 * Slider fields
 * 
 * The default value is the min
 * 
 * @param {Integer} min The minimum value of the slider
 * @param {Integer} max The maximum value of the slider
 */
export const buildFilterSlider = (label, id, min, max, dependency) => {
    return {
        label: label,
        type: 'slider',
        id: id,
        min: min,
        max: max,
        dependency: dependency
    }
}

/**
 * Switch field
 * 
 * @param {Boolean} def The defalt value 
 */
export const buildFilterSwitch = (label, id, def, dependency) => {
    return {
        label: label,
        type: 'switch',
        id: id,
        default: def,
        dependency
    }
}

/**
 * Text field
 * 
 * @param {String} label  
 * @param {String} id 
 * @param (Optional) {Boolean} mandatory 
 * @param (Optional) {Method} validator Method that validates field value. See methods validator<> below in this file
 * @param (Optional) {String} errorMessage A custom message to show if validator returns false. If null, a default error message will be shown.
 * @param (Optional) {String} type. A valid HTML input type (text, number, ...)
 * @param (Optional) {String} info When defined, is displayed below the input field 
 * @param (Optional) {boolean} acceptsZero When defined, mandatory fields accept zero as a valid option
 */
export const buildFilterText = (label, id, mandatory=false, validator=null, errorMessage=undefined, inputType='text', multiline=false, lines=1, maxLength=null, disabled=false, info=undefined, acceptsZero=false) => {
    return {
        label: label,
        type: 'text',
        id: id,
        mandatory: mandatory,
        validator: validator,
        errorMessage: errorMessage,
        inputType: inputType,
        multiline: multiline,
        lines: lines,
        maxLength: maxLength,
        disabled: disabled,
        info: info,
        acceptsZero: acceptsZero
    }
}

export const buildFilterRichText = (label, id, mandatory=false) => {
    return {
        label: label,
        type: 'richtext',
        id: id,
        mandatory: mandatory
    };
}

/**
 * Radio buttons
 * 
 * @param {String} label  
 * @param {String} id 
 * @param {Array} values List of objects {label: String, id: String}
 * @param (Optional) {Boolean} mandatory 
 * @param (Optional) {Boolean} row, if true, options are displayed in a row 
 */
export const buildFilterRadio = (label, id, values, mandatory=false, row=false) => {
    return {
        label: label,
        type: 'radio',
        id: id,
        values: values,
        mandatory: mandatory,
        row: row
        // disabled: disabled (boolean)
    }
}

/**
 * Checkbox (only one)
 * 
 * @param {String} label  
 * @param {String} id 
 * @param (Optional) {Boolean} mandatory 
 */
 export const buildFilterCheckboxOnlyOne = (label, id, mandatory=false) => {
    return {
        label: label,
        type: 'checkboxonlyone',
        id: id,
        mandatory: mandatory
    }
}

/**
 * Checkbox (multiple)
 * 
 * @param {String} label  
 * @param {String} id 
 * @param {Array} values List of objects {label: String, id: String}
 * @param (Optional) {Boolean} mandatory 
 */
 export const buildFilterCheckboxMultiple = (label, id, values, mandatory=false, valsWidth='50%') => {
    return {
        label: label,
        type: 'checkboxmultiple',
        id: id,
        values: values,
        mandatory: mandatory,
        valsWidth: valsWidth
    }
}

/**
 * Date time picker
 */
export const buildFilterDateTime = (label, id, date=true, time=true, mandatory=false, disabled=false, minDate=undefined, maxDate=undefined) => {
    return {
        label: label,
        type: 'datetime',
        id: id,
        mandatory: mandatory,
        disabled: disabled,
        date: date,
        time: time,
        minDate: minDate,
        maxDate: maxDate
    }
}

/**
 * File uploader
 */
export const buildFilterFileUploader = (
    label, 
    id, 
    url, 
    acceptedFiles=[], 
    filesLimit=3,
    initialFiles=[],
    onUpload=undefined, // Method called with server response when upload done
    mandatory=false, 
    // maxFileSize=10 // TODO! Test max size error
    maxFileSize=20000000 // 20 megabytes
) => {
    return {
        type: 'fileupload',
        label: label,
        id: id,
        url: url,
        acceptedFiles: acceptedFiles,
        filesLimit: filesLimit,
        maxFileSize: maxFileSize,
        initialFiles: initialFiles,
        onUpload: onUpload,
        mandatory: mandatory,
    }
}


// VALIDATORS
export const validatorEmail = (email) => {
    return email.trim().match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
}

export const validatorPhone = (phone) => {
    return phone.trim().match(/^[+]*[\s0-9]{9}[\s0-9]*/);
}

export const validatorMin3Chars = (word) => {
    return word.trim().length >= 3;
}

export const validatorIsInteger = (number) => {
    return Number.isInteger(number) || /^\+?(0|[1-9]\d*)$/.test(number.trim());
}

/**
 * Iterates list and for each element build an Object {label:, id:} with the values defined as parameters
 * @param {Object[]} dictList The list of Objects to iterate
 * @param {String} labelKey The key of the label in each object
 * @param {String} idKey The key of the id in each object
 * @returns 
 */
export const getDictsLabelId = (dictList, labelKey, idKey) => {
    return dictList.map((dict) => {
        return {
            label: dict[labelKey],
            id: dict[idKey]
        }
    });
}