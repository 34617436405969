import React, { useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';


import {
    Grid,
    Toolbar,
    IconButton,
    Typography,
    AppBar as MuiAppBar,
    Tooltip
} from '@mui/material';

import {
    Menu as MenuIcon,
    Logout as LogoutIcon
} from '@mui/icons-material';

import {
    getCookie,
    requestGET
} from '../../utils';

const drawerWidth = 0;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Navbar = ({ sidebarOpen, setSidebarOpen }) => {

    const [user, setUser] = useState(undefined);

    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/login/user/', null, true)
            .then(response => {
                console.log("GOT user", response);
                setUser(response);
            })
            .catch(err => {
                if ((err.status===404 || err.name==='TypeError') && window.location.pathname!='/manutencao') {
                    window.location.href = "/manutencao?goBackTo=" + window.location.pathname;
                }
            });
        }, []);

    return (
        <AppBar position="relative" open={sidebarOpen}>
            <Toolbar sx={{display: 'flex', flexWrap: 'wrap'}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setSidebarOpen(old => !old)}
                    edge="start"
                    sx={{ mr: 2, ...(sidebarOpen && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Grid container width="auto" mr="auto">
                    <img
                        src={process.env.REACT_APP_SERVER_URL + '/static/elements/logo.png'}
                        height="48px"
                        width="auto"
                    />
                    {
                        getCookie("env") === "TEST" &&
                        <Typography variant="h6" noWrap component="div" ml={2} sx={{display: {xs: 'none', sm: 'inline-block'}}}>
                            Ambiente de testes
                        </Typography>
                    }
                </Grid>

                <Grid container width="auto" ml='auto' sx={{alignItems: 'center'}}>
                    {
                        user &&    
                        <Typography>
                            Olá, {user.user.first_name}
                        </Typography>
                    }

                    {
                        user &&
                        <Tooltip title="Terminar sessão">
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    window.location.href = process.env.REACT_APP_LOGIN_URL + '/logout';
                                }}
                            >
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>



            </Toolbar>
        </AppBar>
    );
}

export default Navbar;