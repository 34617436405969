import {
    Home as HomeIcon,
    Person as PersonIcon,
    Tv as TvIcon
} from '@mui/icons-material';

import { Badge } from '@mui/material';

export const getMenu = () => {
    let menu = []; // List of {label: <str>, menu: <array>}

    var oldPortal = {menu: []};
    oldPortal.menu.push({
        icon: <HomeIcon />,
        label: 'Portal antigo',
        url: process.env.REACT_APP_ADMIN_URL,
    });
    menu.push(oldPortal);

    var realstate = {label: 'Propriedades', menu: []};
    realstate.menu.push({
        icon: <Badge badgeContent={'Novo'} color={'primary'}><HomeIcon /></Badge>,
        label: 'Propriedades',
        url: '/propriedades'
    });
    realstate.menu.push({
        icon: <Badge badgeContent={'Novo'} color={'primary'}><TvIcon /></Badge>,
        label: 'Apresentações',
        url: '/apresentacoes'
    });
    menu.push(realstate);
    
    var crm = {label: 'Clientes', menu: []};
    crm.menu.push({
        icon: <PersonIcon />,
        label: 'Clientes',
        url: '/clientes'
    });
    menu.push(crm);

    console.log("menu", menu);



    return menu;
}