import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip,
    Button,
    CircularProgress,
    Link,
    // Table 
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell
    // /Table
} from '@mui/material';

import {
    Event as EventIcon,
    Computer as ComputerIcon,
    Chat as ChatIcon,
    Newspaper as NewspaperIcon,
    Person as PersonIcon,
    SyncDisabled as SyncDisabledIcon,
    Notes as NotesIcon,
    Link as LinkIcon,
    ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material';

import {
    is_agent,
    USER_ID,
    canWriteAdministrativeData,
    canReadAdministrativeData
} from "../../../../../Services/permissions";

import {
    requestWithBody,
    handleRequestError,
    DateHelper,
    requestGET
} from "../../../../utils";

import {
    pp_status_approved,
    pp_status_error,
    pp_status_neutral,
    pp_status_pending
} from '../utils';

import Alert from "../../../Chavelusa/Alert";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


const PropertyPublishedWindow = ({ app, property, updateElement, setNotify }) => {

    console.log("COMPONENT PropertyPublishedWindow", app);

    // Operations
    const [loading, setLoading] = useState(!!app.pp);
    const [loadingRequest, setLoadingRequest] = useState(false);

    // Load details when rendered
    const [pp, setPP] = useState(undefined);
    useEffect(() => {
        if (app.pp) {
            requestGET(process.env.REACT_APP_API_URL + '/externalApps/pps/' + app.pp.id + '/?property=' + property.id)
                .then(response => {
                    setPP(response);
                    setLoading(false);
                }).catch(error =>
                    handleRequestError(error, [], "Error getting property published details")
                );
        }
    }, []);

    // Operations
    const execute_op = (op) => {
        setLoadingRequest(true);

        var base_url = process.env.REACT_APP_API_URL + '/externalApps/pps/';
        var body = {operation: op};
        if (op==='publish') {
            body['op_app'] = app.id;
            body['op_property'] = property.id;
        }
        requestWithBody(
            op==='publish' ? 'POST' : 'PATCH',
            op==='publish' ? base_url : base_url + app.pp.id + '/?property=' + property.id,
            body
        ).then(response => {
            console.log("Operation SUCCESS", response);
            updateElement(response);
            setPP(response);
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error executing operation",
            ).then((e) => {
                if ('detail' in e) {
                    setNotify(e['detail'].replace(/<[^>]*>?/gm, ''), true);
                }
            });
        }).finally(() => {
            setLoadingRequest(false);
        });
    };

    return (
        <Grid sx={{width:'100%'}}>
            <Typography variant="h6" mb={0}>{app.name}</Typography>
            {
                app.pp &&
                <>
                    <Typography variant="caption" mb={0}>
                        Ultima publicação a {new Date(app.pp.last_sync).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                    </Typography>
                </>
            }

            { /* State */}
            <Grid xs={12} container>
                <Chip
                    label={app.pp ? app.pp.status : 'Propriedade não anunciada no parceiro'}
                    icon={<NewspaperIcon fontSize="small" />}
                    variant={app.pp && pp_status_error.includes(app.pp.status) ? "filled" : "outlined"}
                    color={
                        app.pp && pp_status_approved.includes(app.pp.status) ? 'primary'
                            : app.pp && pp_status_error.includes(app.pp.status) ? 'error'
                                : app.pp && pp_status_pending.includes(app.pp.status) ? 'warning'
                                    : 'secondary'
                    }
                    sx={{ my: 3, mr: 2 }}
                />
                {
                    app.pp && !app.has_access && canReadAdministrativeData &&
                    <Chip
                        label="Licença expirada"
                        icon={<ErrorOutlineIcon fontSize="small" />}
                        color="error"
                        sx={{ mt: 3, mr:2 }}
                    />
                }
                {
                    app.pp && (new Date(app.pp.last_sync) < new Date(property.last_changed)) &&
                    <Chip
                        label="Propriedade atualizada após publicação do anúncio"
                        icon={<SyncDisabledIcon fontSize="small" />}
                        color="warning"
                        sx={{ mt: 3 }}
                    />
                }
            </Grid>

            { /* Operations */}
            {
                canWriteAdministrativeData &&
                <Grid mb={3} xs={12} container direction='row'>
                    {
                        !app.has_access
                            ? <Grid my={3}>
                                <Typography variant="h6">Licença expirada</Typography>
                                <Typography>A sua licença de utilização do serviço externo expirou e não foi renovada, pelo que as operações junto do parceiro estão bloqueadas.</Typography>
                                <Typography>Entre em contacto com o parceiro para mais informações.</Typography>
                            </Grid>
                            : !app.pp
                                ? <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => execute_op("publish")}
                                    sx={{ mr: 2, mt: 1 }}
                                >
                                    Publicar
                                </Button>
                                : app.pp.user.id !== USER_ID
                                    ? <Typography mb={0}>
                                        Este anúncio foi publicado por {app.pp.user.name} ({app.pp.user.email}), pelo que apenas este o poderá gerir.
                                    </Typography>
                                    : pp && <Grid container>
                                        {
                                            pp.operations.map(op => (
                                                !loadingRequest
                                                    ? <Button
                                                        color="primary"
                                                        variant={op.recommended ? "contained" : "outlined"}
                                                        onClick={() => execute_op(op.code)}
                                                        sx={{ mr: 2, mt: 1 }}
                                                    >
                                                        {op.name}
                                                    </Button>
                                                    : <LoadingButton
                                                        loading
                                                        color="primary"
                                                        variant={op.recommended ? "contained" : "outlined"}
                                                        loadingPosition="start"
                                                        startIcon={<SaveIcon />}
                                                        sx={{ mr: 2, mt: 1 }}
                                                    >
                                                        {op.name}
                                                    </LoadingButton>
                                            ))
                                        }
                                    </Grid>
                    }
                </Grid>
            }

            { /* Details */}
            {
                app.pp &&
                <Grid my={3}>
                    <Typography>
                        <Tooltip title="Publicado a">
                            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {new Date(app.pp.created).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                    </Typography>
                    <Typography>
                        <Tooltip title="Publicado por">
                            <PersonIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {app.pp.user.name}
                    </Typography>
                    {
                        !loading && pp &&
                        <>
                            <Typography>
                                <Tooltip title="Feedback detalhado">
                                    <NotesIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                </Tooltip>
                                {pp.feedback}
                            </Typography>
                            {
                                // Only show URL if advert is active
                                pp.url && (pp_status_approved.includes(pp.status) || pp_status_neutral.includes(pp.status)) &&
                                <Link href={pp.url} target="_blank" underline="none">
                                    <Typography>
                                        <LinkIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                        Anúncio no website do parceiro
                                    </Typography>
                                </Link>
                            }
                        </>
                    }
                </Grid>
            }

            {
                loading &&
                <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }

            {
                !loading && pp && pp.notifications && pp.notifications.length > 0 &&
                <Grid my={3}>
                    <Typography variant="h6">Registo de alterações</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Momento</TableCell>
                                    <TableCell>Utilizador</TableCell>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Fluxo</TableCell>
                                    <TableCell>Evento</TableCell>
                                    <TableCell>Estado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pp.notifications.sort((a, b) => new Date(a.moment) < new Date(b.moment)).map((n) => (
                                    <TableRow
                                        key={n.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {new Date(n.moment).toLocaleDateString('pt-PT', { hour: 'numeric', minute: 'numeric' })}
                                        </TableCell>
                                        <TableCell>{n.user ? n.user.user.name : '-'}</TableCell>
                                        <TableCell>{n.clientRelated ? <Link href={"/clientes/" + n.clientRelated.id} target="_blank" underline="none">{n.clientRelated.name}</Link> : '-'}</TableCell>
                                        <TableCell>{n.flow ? n.flow : '-'}</TableCell>
                                        <TableCell>{n.event ? n.event : '-'}</TableCell>
                                        <TableCell>{n.code ? n.code : '-'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
        </Grid>
    );
}

export default PropertyPublishedWindow;