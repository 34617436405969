import React, { useState, useEffect } from "react";

import {
    Container,
    Button
} from '@material-ui/core';

import {
    requestGET,
    handleRequestError
} from '../../utils';

const Homepage = () => {

    const [user, setUser] = useState(null);

    useEffect(() => {
        // Load filter parameters from API
        requestGET(process.env.REACT_APP_API_URL + '/login/user/')
            .then(response => {
                setUser(response.user);
            }).catch(error => 
                handleRequestError(
                    error, 
                    [], 
                    "Error getting user",
                )
            );
    }, []);

    return (
        <Container>
            {
                user &&
                <>
                    <h1>Olá {user.first_name}, seja bem-vindo/a ao futuro portal da Chave Lusa!</h1>
                    <p>
                        Este portal ainda se encontra em desenvolvimento e não deve ser acedido sem ser através do Painel de Administração.
                    </p>
                    <p>
                        Carregue no botão abaixo para voltar ao painel de administração.
                    </p>
                    <Button variant="contained" href={process.env.REACT_APP_ADMIN_URL}>
                        Painel de administração
                    </Button>
                </>
            }
        </Container>
    );
}

export default Homepage;