import React, { useState, useEffect } from "react";

import {
    requestGET,
    requestWithBody,
    handleRequestError
} from '../../../../../utils';

import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    Chip,
    Tooltip
} from '@mui/material';

import FormGroup from "../../../../Chavelusa/FormGroup";
import {
    buildFilterText,
    buildFilterSelect
} from "../../../../Chavelusa/FormGroup/utils";

import Message from '../../../Message/Message';

const TemplatesFormCustomize = ({ templateId, recipients, success }) => {

    const [template, setTemplate] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [finalPreview, setFinalPreview] = useState(false);

    // Form to customize content
    const [formFields, setFormFields] = useState(undefined);
    const [formInitialVals, setFormInitialVals] = useState({});
    const [vars, setVars] = useState({});

    // When component loads, get template from API 
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/backoffice/emailTemplates/' + templateId)
            .then(response => {
                console.log("GOT template", response);

                // Set template
                setTemplate(response);

                // Build form
                let fields = [
                    {...buildFilterText('Assunto', 'subject', true), maxLength: 120}
                ];
                let initial = {};
                response.varsText.forEach(vt => {
                    fields.push(buildFilterText(vt.label, vt.varname, vt.mandatory, null, undefined, 'text', vt.long, !vt.long ? 1 : 3));
                    initial[vt.varname] = vt.value;
                });
                response.varsModel.forEach(vm => {
                    fields.push(buildFilterSelect(
                        vm.label, 
                        vm.multiple ? 'multipleselect' : 'select' , 
                        vm.varname, 
                        vm.models, 
                        null, 
                        true
                    ));
                });
                setFormFields(fields);
                setFormInitialVals(initial);
                setVars(initial);

                setLoading(false);
            })
    }, []);

    // Update template in real time
    const [html, setHtml] = useState(undefined);
    useEffect(() => {
        if (!template) return;
        if (finalPreview) return;

        let code = template.html;
        for (const [key, value] of Object.entries(vars)) {
            code = code.replaceAll(`{{${key}}}`, value.toString().replaceAll('\n', '<br/>'));
        }
        setHtml(code);
    }, [template, vars, finalPreview]);

    // Preview
    const [error, setError] = useState(undefined);
    const [sender, setSender] = useState(undefined);
    const [subject, setSubject] = useState(undefined);
    const [recipientsData, setRecipientsData] = useState(undefined);
    const sendMessage = (data, preview) => {
        console.log("sendMessage", preview, data);
        setLoading(true);
        setError(undefined);

        let req_vars = {};
        Object.keys(data).forEach(k => {
            if (k!='subject')
                req_vars[k] = data[k];
        });

        requestWithBody(
            "PATCH",
            process.env.REACT_APP_API_URL + '/backoffice/emailTemplates/' + templateId + '/',
            {
                preview: preview,
                vars: req_vars,
                recipients: recipients,
                subject: data['subject']
            },
        ).then(response => {
            if (preview) {
                console.log("Message preview success", response);
                setHtml(response['html']);
                setSender(response['sender']);
                setSubject(response['subject']);
                setRecipientsData(response['recipients']);
                setFinalPreview(true);
                setLoading(false);
            } else {
                console.log("Message sent success", response);
                success(response);
            }
        }).catch(error =>
            handleRequestError(
                error,
                [],
                "Error sending message!",
            )
                .then(e => setError(e))
        ).finally(() => {
            setLoading(false);
        });

    }

    return (
        <Grid container direction="column" sx={{ flexGrow: 1, alignContent: 'flex-start' }}>
            {
                loading
                    ?
                    <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
                    :
                    <Grid container sx={{ flexGrow: 1, alignContent: 'flex-start' }}>
                        <Grid xs={12} mb={3}>
                            <Typography variant="h6">{template.name}</Typography>
                            <Typography variant="body2">{template.description}</Typography>
                        </Grid>

                        <Grid xs={12} mb={3}>
                            <Typography variant="caption">
                                {
                                    !finalPreview
                                        ? "Personalize a sua mensagem no formulário abaixo e quando terminar clique em Submeter para pré-visualizar antes de enviar."
                                        : "Está a um passo de enviar a sua mensagem. Confirme o seu conteúdo abaixo e carregue em enviar para prosseguir."
                                }
                            </Typography>
                        </Grid>

                        {
                            formFields && !finalPreview &&
                            <Grid xs={12} mb={3}>
                                <Typography variant='h6'>Personalizar mensagem</Typography>
                                <FormGroup
                                    fields={formFields}
                                    initialVals={formInitialVals}
                                    updateParams={(_, id, val) => setVars(old => { return { ...old, [id]: val } })}
                                    sx={{ width: '100%' }}
                                    onSubmit={(data) => sendMessage(data, true)}
                                    errors={error}
                                />
                            </Grid>
                        }

                        {
                            finalPreview &&
                            <Grid container direction="column" mb={3} xs={12}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        setFormInitialVals(vars);
                                        setFinalPreview(false)
                                    }}
                                    sx={{ mb: 1 }}
                                >Editar</Button>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => sendMessage(vars, false)}
                                >Enviar</Button>
                            </Grid>
                        }

                        {
                            html &&
                            <Grid xs={12} sx={{ height: finalPreview ? '70%' : '50%' }}>
                                <Typography variant='h6'>Pré-visualização</Typography>
                                <Message 
                                    subject={finalPreview && subject}
                                    sender={finalPreview &&  {
                                        contact: sender.contact,
                                        name: sender.name
                                    }}
                                    recipients={finalPreview && recipientsData.map(r => {return {contact: r.contact, name: r.name};})}
                                    html={html}
                                />
                            </Grid>
                        }
                    </Grid>
            }
        </Grid>
    );
}

export default TemplatesFormCustomize;