import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Button
} from '@mui/material';

import {
    Event as EventIcon,
    AttachFile as AttachFileIcon,
    InsertDriveFile as InsertDriveFileIcon,
    FormatListNumbered as FormatListNumberedIcon
} from '@mui/icons-material';

import {
    DateHelper,
    requestGET,
    requestWithBody,
    handleRequestError
} from '../../../../utils';

import {
    buildFilterSelect
} from '../../../Chavelusa/FormGroup/utils';

import {
    canWriteSensitiveData
} from '../../../../../Services/permissions';

import { PROPERTY_STATUS_ALLOW_WRITE_ALWAYS } from "../../../../../Models/property";

import FormGroup from "../../../Chavelusa/FormGroup";
import ChaveLusaAlert from "../../../Chavelusa/Alert";

/**
 * Property image banner
 * 
 * MANDATORY
 * @param {Object}  instance        Instancce to be displayed in the banner
 * 
 * OPTIONAL
 * @param {Integer} index           Index of banner to set padding-left/right
 * 
 * OPTIONAL (only if updateElement is set)
 * @param {Method}  updateElement   Method to update instance               (instance:Object, remove:Boolean)
 * @param {Method}  notify          Method to pop up notification           (message:String, error:Boolean)
 * @param {Boolean} editionMode     If true and categories defined, select to update category is shown
 * @param {List}    categories      List of objects {id:<Integer>, label:<String>}
 * @param {String}  editUrl         URL to PATCH category changes
 * 
 */
const PropertyImageBanner = ({ instance, updateElement, notify, editionMode, categories, editURL, index, property }) => {

    // Edition mode
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(undefined);
    const [alert, setAlert] = useState(undefined);

    const syncChanges = (val) => {
        setSubmitting(true);
        requestWithBody("PATCH", editURL, {
            category_update: val ? val : 0
        }).then(response => {
            console.log("INSTANCE UPDATE SUCCESS", response);
            updateElement(response);
            notify("Imagem atualizada com sucesso");
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error updating instance",
            ).then(e => {
                setError(e);
                notify("Ocorreu um erro, tente novamente", true);
            });
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const deleteInstance = () => {
        setSubmitting(true);
        requestWithBody("DELETE", editURL, null)
            .then(response => {
                console.log("INSTANCE DELETE SUCCESS", response);
                notify("Imagem eliminada com sucesso");
                updateElement(instance, true);
            }).catch(error => {
                handleRequestError(
                    error,
                    [],
                    "Error deleting instance",
                ).then(e => {
                    notify("Ocorreu um erro, tente novamente", true);
                });
            }).finally(() => {
                setSubmitting(false);
            });
    }

    const ALERT_CONFIRM_DELETE = {
        title: 'Quer mesmo eliminar esta imagem?',
        text: 'Esta operação é irreversível!',
        action: deleteInstance
    };

    return (
        <Grid xs={6} pl={index!==undefined && index%2===0 ? 0 : 2} pr={index!==undefined && index%2!==0 ? 0 : 2}>
            <Grid
                container
                direction="column"
                my={4}
                className="elementSelectable disabled"
            >
                <img
                    src={process.env.REACT_APP_STATIC + instance.image_url}
                    style={{ width: '100%', height: 'auto' }}
                />
                <Grid
                    justifyContent="center"
                    container
                    direction="column"
                    p={3}
                    xs={12}
                >
                    <Typography variant="h6" mb={0} sx={{ wordBreak: 'break-all' }}>{instance.name}</Typography>
                    {
                        !editionMode
                            ? <Grid container>
                                {
                                    instance.label &&
                                    <Typography variant="caption" mr={2}>
                                        <Tooltip title="Categoria">
                                            <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                        </Tooltip>
                                        {instance.label.label}
                                    </Typography>
                                }
                                <Typography variant="caption" mr={2}>
                                    <Tooltip title="Ordem">
                                        <FormatListNumberedIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                    </Tooltip>
                                    {instance.order+1}
                                </Typography>
                                <Typography variant="caption" mr={2}>
                                    <Tooltip title="Carregado a">
                                        <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                    </Tooltip>
                                    {new Date(instance.created).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                                </Typography>
                            </Grid>
                            : <Grid mt={1}>
                                {
                                    categories &&
                                    <FormGroup
                                        fields={[
                                            buildFilterSelect(
                                                "Categoria",
                                                "select",
                                                "category",
                                                categories.map(c => { return { id: c.id, label: c.label } }),
                                                null,
                                                false,
                                                null,
                                                undefined,
                                                true
                                            )
                                        ]}
                                        updateParams={(to_clean, id, val) => {
                                            console.log("updateParams", to_clean, id, val);
                                            if (id === "category") {
                                                syncChanges(val === "" ? null : val);
                                            }
                                        }}
                                        initialVals={instance && instance.label ? { category: instance.label } : {}}

                                        disabled={submitting}
                                        errors={error}
                                        setErrors={setError}

                                        sx={{ width: '100%' }}
                                    />
                                }
                                {
                                    (PROPERTY_STATUS_ALLOW_WRITE_ALWAYS.includes(property.status) || canWriteSensitiveData) &&
                                    <Button
                                        color="warning"
                                        variant="outlined"
                                        onClick={() => setAlert(ALERT_CONFIRM_DELETE)}
                                        sx={{ mr: 1, mb: 1, mt:1, width: '100%' }}
                                        disabled={submitting}
                                    >Eliminar</Button>
                                }
                            </Grid>
                    }

                </Grid>
            </Grid>

            { /* Operations confirmation box */}
            {
                alert &&
                <ChaveLusaAlert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default PropertyImageBanner;