import React, { useState, useEffect } from "react";

import {
    Chip,
    Grid,
    Tooltip
} from '@material-ui/core';

import {
    LocalOffer as LocalOfferIcon,
    SupervisorAccount as SupervisorAccountIcon,
    ErrorOutline as ErrorOutlineIcon,
    Chat as ChatIcon,
    Event as EventIcon
} from '@mui/icons-material';

import { is_agent } from "../../../../Services/permissions";


const Tags = ({ property }) => {

    return (
        <>
            <Grid container direction="row" mt={2} className="tags">
                <Grid mr={1}>
                    <Chip
                        label={property.status_display}
                        icon={<LocalOfferIcon fontSize="small" />}
                        color={property.status_is_inactive ? "secondary" : property.status_is_pending ? "warning" : "primary"}
                        variant={property.status_is_pending ? "filled" : "outlined"}
                        mr={1}
                    />
                </Grid>
                {
                    property.errors && Object.keys(property.errors).length > 0 &&
                    <Grid mr={1}>
                        <Chip
                            label={"Ficha do imóvel incompleta"}
                            icon={<ErrorOutlineIcon fontSize="small" />}
                            color={"warning"}
                            variant={"filled"}
                        />
                    </Grid>
                }
                {
                    property.forms_pending &&
                    <Grid mr={1}>
                        <Chip
                            label={"Formulários de contacto pendentes"}
                            icon={<ChatIcon fontSize="small" />}
                            color={"warning"}
                            variant={"filled"}
                        />
                    </Grid>
                }
            </Grid>
            <Grid container direction="row" mt={2}>
                <Grid mr={1}>
                    <Chip
                        label={`Criada a ${new Date(property.created).toLocaleDateString('pt-PT')}`}
                        icon={<EventIcon fontSize="small" />}
                        color="primary"
                        variant="outlined"
                    />
                </Grid>
                <Grid mr={1}>
                    <Chip
                        label={property.agent.name}
                        icon={<SupervisorAccountIcon fontSize="small" />}
                        color="primary"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default Tags;